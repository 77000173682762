import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom";
import { client } from 'websocket';

import { masterUser } from "../../components/tools";
import { UserContext, isBrand } from "../../components";
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { IoIosArrowForward } from "react-icons/io";
import { CiCreditCard1 } from "react-icons/ci";
import { PiUserListLight } from "react-icons/pi";


export default function Admin() {
    const login = JSON.parse(localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : JSON.parse(sessionStorage.getItem('login'))
    const navigate = useNavigate();
    document.title = 'Admin'

    const { setListClientSearch, listClientSearch, setNotification, clientRoles } = useContext(UserContext);

    client.onmessage = (message) => {
        let data = WsAnal(message)
        // console.log('new data', data);
        if (data) {
            // update message
            if (data.length > 0) {
                setListClientSearch(WSdataList(data, listClientSearch, 11));
            }
            // first new message from query
            else {
                if (data) {
                    if (data.id === 11) setListClientSearch(WSdata(data, null, 11));
                    if (data.id === 26) setNotification(WSdata(data, null, 26));
                }

            }
        }
    }

    useEffect(() => {
        if (!masterUser.includes(login.uname) && clientRoles.some(i => i.type.symbol === 'data_curator')) navigate('/')
    }, [])


    return (
        <div className="bg-white d-flex align-items-center justify-content-center config" style={{ minHeight: '100vh' }}>
            <div className="w-50 d-flex flex-column align-items-center shadow mb-5 bg-body rounded">
                <div className="d-flex w-100"><h3 className="my-4 ms-5 ps-3">Admin</h3></div>
                <Link to="/admin/scard" className="w-100  text-decoration-none">
                    <Button variant="light" className="d-flex align-items-center w-100 px-4">
                        <div className="icon"><CiCreditCard1 className="mx-2 fs-3 text-primary" />
                        </div>
                        <div className="ms-4 fs-5 text">DDD - karty podniku </div>
                        <div className="m-auto"></div>
                        <div className="icon"><IoIosArrowForward className="fs-1 mx-2 text-secondary" /></div>
                    </Button>
                </Link>
                <Link to="/admin/allClients" className="w-100  text-decoration-none">
                    <Button variant="light" className="d-flex align-items-center w-100 px-4">
                        <div className="icon"><PiUserListLight  className="mx-2 fs-3 text-primary" />
                        </div>
                        <div className="ms-4 fs-5 text">Zákazníci</div>
                        <div className="m-auto"></div>
                        <div className="icon"><IoIosArrowForward className="fs-1 mx-2 text-secondary" /></div>
                    </Button>
                </Link>
            </div>
        </div>
    )
}